<template>
  <div class="relative items-center justify-center hidden px-6 py-2 overflow-hidden text-sm font-medium bg-gray-500 bg-opacity-70 backdrop-blur-sm lg:flex drop-shadow-menu">
    <Transition enter-active-class="transition duration-150 ease-out"
                enter-from-class="translate-y-12"
                enter-to-class="translate-y-0"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0"
                leave-to-class="-translate-y-12"
                mode="out-in"
    >
      <ol :key="items[0].id"
          class="flex space-x-4"
      >
        <li v-for="link in items"
            :key="link.id"
        >
          <NuxtLink class="flex px-3 py-1.5 transition duration-100 rounded-[5px]"
                    :class="{
                      'bg-gray-100 bg-opacity-10 text-gray-100': link.url === url,
                      'text-gray-200': link.url !== url
                    }"
                    :href="link.url"
                    :data-id="link.id"
                    @mouseover="handleHover"
                    @mouseleave="handleHover()"
          >
            {{ link.title }}

            <div v-if="link.slug === 'jobs' && jobs"
                 class="flex items-center justify-center w-5 h-5 ml-1 rounded-full pointer-events-none bg-gradient-red"
            >
              <div class="-mt-0.5 text-xs text-white">
                {{ jobs }}
              </div>
            </div>
          </NuxtLink>
        </li>
      </ol>
    </Transition>
    <div ref="submenuHoverElement"
         class="absolute h-8 bg-opacity-10 bg-gray-100 rounded-[5px] px-3 py-1.5 pointer-events-none"
         :style="{
           left: `${submenuHover.offsetLeft}px`,
           width: `${submenuHover.clientWidth}px`,
           opacity: submenuHover.opacity
         }"
    />
    <div class="absolute bottom-0 hidden w-full h-px duration-200 border-b md:block border-gray-450" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { MenuItemType } from '../../types/menu';

interface DesktopSubmenuProps {
  items: Array<MenuItemType>;
  url: string;
  jobs: number;
}

type SubmenuHoverType = {
  offsetLeft: number;
  clientWidth: number;
  opacity: number;
};

defineProps<DesktopSubmenuProps>();
const timeoutEvent = ref(null);

const submenuHoverElement = ref();
const submenuHover = ref<SubmenuHoverType>({
  offsetLeft: 0,
  clientWidth: 0,
  opacity: 0
});

/**
 * Handle submenu hover
 *
 * On submenu hover we set the submenuHover object values to the values of the
 * hovered item. This is used to animate the submenuHoverElement
 *
 * @param event
 */
function handleHover(event?: MouseEvent) {
  // If event, we are hovering over an item
  if (event) {
    // Clear timeout event, since we no longer need to run it, while we are hovering
    clearTimeout(timeoutEvent.value);
    const item = event.target as HTMLElement;
    const rect = item.getBoundingClientRect();

    // If the opacity was 0, we set the hover position
    // After a slight delay (10ms), we add the animation duration class
    // to avoid the animation to start from the x = 0 position
    if (submenuHover.value.opacity === 0) {
      submenuHover.value = {
        offsetLeft: rect.left,
        clientWidth: rect.width,
        opacity: 0
      };

      setTimeout(() => {
        submenuHoverElement.value?.classList.add('duration-300');
        submenuHover.value = {
          ...submenuHover.value,
          opacity: 1
        };
      }, 10);
    } else {
      // In case the opacity is not 0, we just update the position
      submenuHover.value = {
        offsetLeft: rect.left,
        clientWidth: rect.width,
        opacity: 1
      };
    }
  } else {
    // If no event, we are not hovering over an item, set timeout to clear the hover
    // and animation duration
    timeoutEvent.value = setTimeout(() => {
      submenuHoverElement.value?.classList.remove('duration-300');
      submenuHover.value = {
        ...submenuHover.value,
        opacity: 0
      };
    }, 150);
  }
}
</script>
